<template>
  <div v-for="(question,index) in questions" :key="index">
    <div class="question-title">
      <h4> {{ $t('DIAS.PHISHING.QUESTION') + " " + (index + 1) }}      </h4>
      <InlineButton v-if="questions.length > minQuestions"
                    :text="$t('DIAS.NETIQUETTE.REMOVE_QUESTION')"
                    :icon="require('@/assets/icons/icn_delete.svg')"
                    @click="removeQuestion(index)"
      />
    </div>
    <Dropdown :id="`question-type-sub-${index}`"
              :field-title="''"
              :dropdown-options="types"
              :placeholder="''"
              :field-name="'group'"
              :selected-option="question.type"
              :callback="(value) => updateQuestionType(index,value)"
              :api-error-text="''"
              class="form-element">
    </Dropdown>
    <div class="question-content-wrapper">

      <Chat v-if="question.type.id === 1"
            :slide-data="question"
            @data="(key,value) => updateData(index,key,value)"
            @verify="(valid) => setValid(index,valid)"
      />
      <Comment v-if="question.type.id === 2"
               :slide-data="question"
               :media-files="initialData ? initialData.mediaFiles : null"
               @data="(key,value) => updateData(index,key,value)"
               @verify="(valid) => setValid(index,valid)"
      />
      <FriendRequest v-if="question.type.id === 3"
                     :slide-data="question"
                     @data="(key,value) => updateData(index,key,value)"
                     @verify="(valid) => setValid(index,valid)"
      />
    </div>
  </div>
  <InlineButton v-if="questions.length < maxQuestions"
                :text="$t('DIAS.NETIQUETTE.ADD_QUESTION')"
                :icon="require('@/assets/icons/icn_add.svg')"
                @click="addQuestion()"
  />
</template>

<script>

import InlineButton from '../../../elements/basic/InlineButton.vue'
import Dropdown from '@/components/yo-form/Dropdown.vue'
import Chat from './Netiquette/Chat.vue'
import FriendRequest from './Netiquette/FriendRequest.vue'
import nl from '@/utils/language/nl.json'
import Comment from './Netiquette/Comment'
import { TYPES_TRUE_OR_FALSE } from '@/utils/helpers/mission/SelectAnswerConsts.js'
import { GET_NETIQUETTE_TYPES } from '@/store/modules/cms/missions/actions'
import { mapGetters } from 'vuex'
export default {
  name: 'Netiquette',
  components: {
    Chat,
    Comment,
    FriendRequest,
    Dropdown,
    InlineButton,
  },
  props: {
    initialData: {
      type: Object,
      required: false,
      default: null
    },
    showErrors: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['data', 'verify'],
  data() {
    const minQuestions = 1
    const maxQuestions = 4
    const defaultType = {
      id: 1,
      name: 'Chat'
    }
    const defaultQuestion = {
      type: this.types ? this.types[0] : defaultType,
      valid: false
    }
    const questions = this.initialData ? this.convertInitialData() : [{ ...defaultQuestion }]
    return {
      minQuestions,
      maxQuestions,
      defaultQuestion,
      questions
    }
  },
  computed: {
    ...mapGetters({
      types: 'getNetiquetteSlideTypes'
    })
  },
  watch: {
    questions: {
      handler(val) {
        this.onUpdate()
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch(GET_NETIQUETTE_TYPES)
    this.onUpdate()
  },
  methods: {
    convertInitialData() {
      const questions = this.initialData.contentQuestionJson
      const newSlides = questions.subSlides.map((slide, index) => {
        const newSlide = slide
        if (newSlide.mediaFileUrl) {
          newSlide.image = newSlide.mediaFileUrl
        }
        newSlide.type = slide.netiquetteType
        newSlide.isOk = slide.correctAnswer === 1
        if (slide.messages) {
          newSlide.comments = slide.messages
          newSlide.senders = slide.messages.map(x => x.isMe)
          newSlide.messages = slide.messages.map(x => x.message)
        }
        return newSlide
      })
      return newSlides
    },
    addQuestion() {
      if (this.questions.length < this.maxQuestions) {
        this.questions.push({ ...this.defaultQuestion })
      }
    },
    removeQuestion(index) {
      if (this.questions.length > this.minQuestions) {
        this.questions.splice(index, 1)
      }
    },
    updateQuestionType(index, type) {
      this.questions[index] = { ...this.defaultQuestion }
      this.questions[index].type = type
    },
    updateData(index, key, value) {
      this.questions[index][key] = value
    },
    onUpdate() {
      this.$emit('data', {
        slideType: TYPES_TRUE_OR_FALSE.NETIQUETTE,
        questions: this.questions
      })
      this.$emit('verify', this.questions.every(x => x.valid))
    },
    setValid(index, value) {
      this.questions[index].valid = value
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";



.question-content-wrapper{
  margin: rem(20) rem(0);
  padding-left: rem(20);
  border-left: black 2px solid;
}

.question-title{
  display: flex;
  margin-bottom: rem(10);
  justify-content: space-between;
}

</style>
