<template>
  <div class="content-container">
    <PageTitle :title="$t('MISSIONS.TITLE')" class="page-title">
<!--      <template #extra-content>-->
<!--        <basic-button class="example" :button-text="$t('DIAS.EXAMPLE')" :icon="require('@/assets/icons/icn_visibility.svg')" :button-class="'blue-button'" />-->
<!--      </template>-->
    </PageTitle>

    <h1 v-if="canShowDia" class="page-section-title section-title">{{ $t(initialData ? 'DIAS.EDIT' : 'DIAS.CREATE_NEW') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')" :route-name="ROUTE_NAMES_CMS.MISSION_DETAILS" :pre-leave-check="preLeaveCheck"/>

    <!-- Form -->
    <DiaFormText v-if="type == 0 && canShowDia" :id="Number.parseInt(id)" :initial-data="initialData" />
    <DiaFormImage v-if="type == 1 && canShowDia" :id="Number.parseInt(id)" :initial-data="initialData" />
    <DiaFormVideo v-if="type == 2 && canShowDia" :id="Number.parseInt(id)" :initial-data="initialData" />
    <DiaFormDragDrop v-if="type == 3 && canShowDia" :id="Number.parseInt(id)" :initial-data="initialData" />
    <DiaFormSelectAwnser v-if="type == 4 && canShowDia" :id="Number.parseInt(id)" :initial-data="initialData" />
    <DiaFormDilemma v-if="type == 5 && canShowDia" :id="Number.parseInt(id)" :initial-data="initialData" />
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import { ref } from 'vue'
import { GET_SLIDE_DETAILS, RESET_SLIDE_DETAILS } from '@/store/modules/cms/missions/actions.js'

import DiaFormText from '@/components/partials/missions/DiaForms/DiaFormText.vue'
import DiaFormDilemma from '@/components/partials/missions/DiaForms/DiaFormDilemma.vue'
import DiaFormDragDrop from '@/components/partials/missions/DiaForms/DiaFormDragDrop.vue'
import DiaFormImage from '@/components/partials/missions/DiaForms/DiaFormImage.vue'
import DiaFormSelectAwnser from '@/components/partials/missions/DiaForms/DiaFormSelectAwnser.vue'
import DiaFormVideo from '@/components/partials/missions/DiaForms/DiaFormVideo.vue'
import BasicButton from '@/components/elements/basic/BasicButton.vue'

export default {
  name: 'CreateDia',
  components: {
    BackButton,
    PageTitle,
    DiaFormText,
    DiaFormDilemma,
    DiaFormDragDrop,
    DiaFormImage,
    DiaFormSelectAwnser,
    DiaFormVideo,
    // BasicButton,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    id: { //Id for the mission if needed
      type: String,
      required: true,
    },
    slideId: { //If this value is true
      type: String,
      default: null,
    }
  },
  setup(props, context) {
    const store = useStore()
    const initialData = computed(() => props.slideId ? store.getters.getSlideDetails : null)
    const canShowDia = computed(() => !props.slideId || initialData.value !== null)
    if (props.slideId) { //we are editin
      store.dispatch(RESET_SLIDE_DETAILS, props.slideId)
      store.dispatch(GET_SLIDE_DETAILS, props.slideId)
    }

    function preLeaveCheck() {
      return true
    }

    return {

      preLeaveCheck,
      initialData,
      ROUTE_NAMES_CMS,
      canShowDia,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";


.border-line{
  border-top: 2px solid #DDE3F0;
  margin-bottom: rem(20);
  margin-top: rem(20);
}

.example {
  display: flex;
  margin-left: auto;
  align-items: center;
  height: rem(41);

  img {
    color: white;
    width: rem(24);
    height: rem(24);
  }
}
</style>
