<template>
  <FormWrapper :send-form-data="validateData" class="page-form">
    <template #form-content>
      <h3>{{ $t('DIAS.VIDEO') }} {{ $t('DIA') }}</h3>
      <InputField
        :field-title="$t('DIAS.TITLE')"
        :field-name="'title'"
        :rules="'required'"
        :type="'text'"
        :placeholder="$t('DIAS.TITLE_PLACEHOLDER')"
        :cy-selector="'name-input'"
        class="form-element"
        :value="initialData ? initialData.title : null"
      />
      <RichTextField
        :field-title="$t('DIAS.DESCRIPTION')"
        :placeholder="$t('DIAS.DESCRIPTION_PLACEHOLDER')"
        :input-callback="updateDescription"
        :value="initialData ? initialData.description : null"
        :error-text="textInputError"
      />
      <SelectVideo :initial-selection="video"
                   @selected="(newVideo) => videoSelected(newVideo)"/>

    </template>

    <template #button-submit>
      <button type="submit" class="pink-button submit-button">
        {{ $t('CREATE_MISSION.SAVE_AND_CONTINUE') }}
      </button>
    </template>
  </FormWrapper>
</template>

<script>
import { ref } from '@vue/reactivity'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import { CREATE_DIA } from '@/store/modules/cms/missions/actions'
import RichTextField from '@/components/elements/RichTextEditor/RichTextField'
import SelectVideo from '@/components/elements/inputFields/SelectVideo'
export default {
  name: 'Details',
  components: {
    InputField,
    FormWrapper,
    RichTextField,
    SelectVideo
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    initialData: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  setup(proxy) {
    const store = useStore()
    const router = useRouter()
    const description = ref(proxy.initialData ? proxy.initialData.description : null)
    const slideId = proxy.initialData ? proxy.initialData.id : null
    const textInputError = ref(null)
    const videoInputError = ref(null)
    const video = ref(proxy.initialData ? proxy.initialData.contentQuestionJson.video : null)

    function validateData(data) {
      if (!handleErrors(data.video)) {
        return
      }

      data.missionId = proxy.id
      data.description = description.value
      data.videoId = video.value.id
      store.dispatch(CREATE_DIA, { data, type: 'video', slideId })
        .then(response => {
          if (response && (response.status === 201 || response.status === 204)) {
            router.push({ name: ROUTE_NAMES_CMS.MISSION_DETAILS })
          } else {
            console.log(response)
          }
        })
    }



    function videoSelected(newVideo) {
      video.value = newVideo
    }


    function updateDescription(input) {
      description.value = input
    }

    // const options = [
    //   'Upload from pc', //$t('CREATE_DIA_IMAGE.UPLOAD_FROM_PC')
    //   'Kies van de bibliotheek'//$t('CREATE_DIA_IMAGE.CHOOSE_FROM_LIBRARY')
    // ]

    function handleErrors(videoUrl) {
      let success = true
      clearErrors()

      if (description.value === '') {
        setError(textInputError, 'De tekst is verplicht.')
        success = false
      }
      if (videoUrl === '') {
        setError(videoInputError, 'De video url is verplicht')
        success = false
      }

      return success
    }

    function setError(error, text) {
      error.value = text
    }

    function clearErrors() {
      textInputError.value = ''
      videoInputError.value = ''
    }

    return {
      validateData,
      // options,
      videoSelected,
      updateDescription,
      textInputError,
      videoInputError,
      video
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";


h3 {
  margin-bottom: rem(20);
}

h4 {
    color: var(--blue_dark);
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.1875rem;
    margin-bottom: 0.5rem;
    text-align: left;
    margin-top: rem(20);
}

.File {
 margin-right: 100%;
 width: rem(215);
}
</style>
